import React, { createContext, useState } from "react";

import ConveniencesApi from "../services/conveniences";

const ConveniencesStore = createContext(null);

const ConveniencesProvider = ({ children }) => {
  const [conveniences, setConveniences] = useState([]);

  const state = { conveniences };

  const actions = {
    // 편의점 목록
    fetchConveniences: ({ token, centerLat, centerLng }) => {
      ConveniencesApi.fetchConveniences({
        token,
        centerLat,
        centerLng,
      }).then((response) => {
        setConveniences(response);
      }).catch(() => {
        setConveniences([]);
      });
    },
  };

  return (
    <ConveniencesStore.Provider value={{ state, actions }}>
      {children}
    </ConveniencesStore.Provider>
  );
};

export { ConveniencesStore, ConveniencesProvider };
