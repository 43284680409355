import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";

import useConveniencesStore from "../contexts/useConveniencesStore";
import useMapStore from "../contexts/useMapStore";

const MapContainer = styled.div``;

const MapWrap = styled.div`
  width: 100%;
  height: 100vh;
`;

const ButtonWrap = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
  z-index: 100;
`;
const CurrentButton = styled.button`
  border: none;
  width: 32px;
  height: 32px;
  background-size: cover;
  background-image: url(/assets/images/icon_current.png);
`;

const ConvenienceInfo = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 18px 12px 12px;
  background-color: #fff;
  z-index: 100;
`;
const ConvenienceInfoName = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #2c2415;
  line-height: 1;
  margin-bottom: 5px;
  padding: 0 7px;
`;
const ConvenienceInfoAddr = styled.div`
  color: #abacac;
  font-size: 16px;
  padding: 0 7px;
  margin-bottom: 5px;
  min-height: 45px;
`;
const ConvenienceInfoRow = styled.div`
  margin-left: -2px;
  margin-right: -2px;
  display: flex;
  flex-direction: row;
`;
const ConvenienceInfoCol = styled.div`
  padding-left: 2px;
  padding-right: 2px;
  flex: 1;
`;
const ConvenienceInfoButton = styled.button`
  width: 100%;
  border: none;
  height: 40px;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;

  ${({ gray }) => gray && "background-color: #a6a7a7"}
  ${({ blue }) => blue && "background-color: #00a0e9"}
`;

function load(url, cb, err) {
  let element = document.createElement("script");

  element.async = true;
  element.onload = cb;
  element.onerror = err;

  element["src"] = url;
  document["head"].appendChild(element);
}

export default function Map() {
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");

  const { conveniences } = useConveniencesStore().state;
  const { fetchConveniences } = useConveniencesStore().actions;

  const { map, updated } = useMapStore().state;
  const { drawMap, updateCurrentPostion } = useMapStore().actions;

  const [rendered, setRendered] = useState(false);

  const [currentConvenience, setCurrentConvenience] = useState({});
  const [showInfo, setShowInfo] = useState(false);

  let markers = useRef([]);
  let infoWindows = useRef([]);
  let markerConveniences = useRef([]);

  useEffect(() => {
    load(
      "https://oapi.map.naver.com/openapi/v3/maps.js?ncpClientId=208d0haqxg",
      () => {
        drawMap();
        setRendered(true);
      },
    );

    return () => {
      window.naver.maps.Event.removeListener();
      document.removeEventListener("message", null);
      window.removeEventListener("message", null);
    };
  }, []);

  let _conveniences = useRef([]);
  useEffect(() => {
    if (map) {
      fetchConveniences({
        token,
        centerLat: map.center._lat,
        centerLng: map.center._lng,
      });

      if (rendered) {
        new window.naver.maps.Event.addListener(map, "dragend", function () {
          if (map) {
            fetchConveniences({
              token,
              centerLat: map.center._lat,
              centerLng: map.center._lng,
            });
          }
        });

        const userAgent = navigator.userAgent.toLowerCase();

        // util 에서 userAgent를 판단해서 document/windonw
        // 무엇을 보낼지 모르니 JSON.parse . try / catch
        if (userAgent.search("android") > -1) {
          document.addEventListener("message", function (e) {
            if (e.data) {
              updateCurrentPostion(JSON.parse(e.data));
            }
          });
        } else {
          window.addEventListener("message", function (e) {
            if (e.data) {
              updateCurrentPostion(JSON.parse(e.data));
            }
          });
        }

        setRendered(false);
      }

      return () => {
        window.naver.maps.Event.removeListener();

        document.removeEventListener("message", null);
        window.removeEventListener("message", null);
      };
    }
  }, [map, rendered, updated]);

  useEffect(() => {
    if (map) {
      _conveniences.current = conveniences;

      drawMarker();
    }
  }, [conveniences, rendered]);

  const drawMarker = useCallback(() => {
    for (var i = 0; i < markers.current.length; i++) {
      markers.current[i].setMap(null);

      let infoWindow = infoWindows.current[i];

      if (infoWindow.getMap()) {
        infoWindow.close();
      }
    }

    markers.current = [];
    infoWindows.current = [];
    markerConveniences.current = [];

    if (_conveniences.current && _conveniences.current.length > 0 && map) {
      _conveniences.current.forEach((item) => {
        if (
          item.point &&
          Number(item.point.y) >= map.bounds._min.x &&
          Number(item.point.x) >= map.bounds._min.y &&
          Number(item.point.y) <= map.bounds._max.x &&
          Number(item.point.x) <= map.bounds._max.y
        ) {
          let marker = new window.naver.maps.Marker({
            map,
            position: new window.naver.maps.LatLng(item.point.x, item.point.y),
            title: item.name,
            zIndex: 100,
            icon: {
              url: `/assets/images/icon_${
                item.company === "GS"
                  ? "gs25"
                  : item.company === "emart24"
                  ? "emart"
                  : "cu"
              }.png`,
              size: new window.naver.maps.Size(28, 40),
              scaledSize: new window.naver.maps.Size(28, 40),
            },
          });

          let infoWindow = new window.naver.maps.InfoWindow({
            content:
              "<div style='width:150px;text-align:center; padding:5px; border-radius: 10px;'>" +
              item.name +
              "</div>",
            anchorSize: {
              width: 10,
              height: 8,
            },
          });

          markers.current.push(marker);
          infoWindows.current.push(infoWindow);
          markerConveniences.current.push(item);
        }
      });

      window.naver.maps.Event.addListener(map, "idle", function () {
        updateMarkers(map, markers.current);
      });

      for (var j = 0, jj = markers.current.length; j < jj; j++) {
        window.naver.maps.Event.addListener(
          markers.current[j],
          "click",
          clickMarker(j),
        );
      }
    }
  }, [map]);

  const updateMarkers = useCallback(() => {
    // console.log("markers", markers.current);
    let mapBounds = map.getBounds();
    let marker, position;

    for (var i = 0; i < markers.current.length; i++) {
      marker = markers.current[i];
      position = marker.getPosition();

      if (mapBounds.hasLatLng(position)) {
        showMarker(map, marker);
      } else {
        hideMarker(marker);
      }
    }
  }, [map]);

  function showMarker(map, marker) {
    if (marker.setMap()) return;
    marker.setMap(map);
  }

  function hideMarker(marker) {
    if (!marker.setMap()) return;
    marker.setMap(null);
  }

  function clickMarker(seq) {
    return function () {
      const infoWindow = infoWindows.current[seq];

      if (infoWindow.getMap()) {
        infoWindow.close();
      } else {
        infoWindow.open(map, markers.current[seq]);
      }

      setCurrentConvenience(markerConveniences.current[seq]);
      setShowInfo(true);
    };
  }

  const hideInfo = () => {
    setShowInfo(false);
  };

  // 지도앱 열기 : 나가바앱에서 열도록 호출
  const linkMapApp = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(currentConvenience));
    }
  };

  // 현재 위치 조회
  const getCurrentPosition = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("currentPosition");
    }
  };

  return (
    <MapContainer>
      <MapWrap id="wrap" />
      <ButtonWrap>
        <CurrentButton onClick={getCurrentPosition} />
      </ButtonWrap>

      {showInfo && currentConvenience && (
        <ConvenienceInfo>
          <ConvenienceInfoName>{currentConvenience.name}</ConvenienceInfoName>
          <ConvenienceInfoAddr>
            {currentConvenience.address}
          </ConvenienceInfoAddr>
          <ConvenienceInfoRow>
            <ConvenienceInfoCol>
              <ConvenienceInfoButton gray onClick={linkMapApp}>
                지도앱 열기
              </ConvenienceInfoButton>
            </ConvenienceInfoCol>
            <ConvenienceInfoCol>
              <ConvenienceInfoButton blue onClick={hideInfo}>
                닫기
              </ConvenienceInfoButton>
            </ConvenienceInfoCol>
          </ConvenienceInfoRow>
        </ConvenienceInfo>
      )}
    </MapContainer>
  );
}
