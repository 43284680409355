export default {
  /**
   * 편의점 목록
   */
  fetchConveniences: ({ token, centerLat, centerLng }) =>
    fetch(
      `${process.env.REACT_APP_API_HOST}/stores?centerLat=${centerLat}&centerLng=${centerLng}&zoomLevel=0.01`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then((responseJson) => responseJson)
      .catch((err) => {
        console.log("err", err);
      }),

  /**
   * 편의점 외부링크를 위해 다음지도 아이템 가져오기
   */
  fetchConvenienceByDaum: ({ name }) =>
    fetch(`https://dapi.kakao.com/v2/local/search/keyword.json?query=${name}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `KakaoAK ${process.env.REACT_APP_DAUM_KEY}`,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((responseJson) => responseJson)
      .catch((err) => {
        console.log("err", err);
      }),
};
