import React, { createContext, useState } from "react";

const MapStore = createContext(null);

const MapProvider = ({ children }) => {
  const [map, setMap] = useState(null);
  const [updated, setUpdated] = useState(false);

  const state = {
    map,
    updated,
  };

  let _map = null;
  let currentMarker = null;

  const actions = {
    drawMap: () => {
      const mapOptions = {
        center: new window.naver.maps.LatLng(37.571717, 126.976479),
        zoom: 16,
        minZoom: 13,
      };

      _map = new window.naver.maps.Map("wrap", mapOptions);

      setMap(_map);

      setUpdated(true);
    },
    updateCurrentPostion: (_location) => {
      setCurrentPostionMarker(_location);
    },
  };

  const setCurrentPostionMarker = (position) => {
    setUpdated(false);

    if (currentMarker) {
      currentMarker.setMap(null);
      currentMarker = null;
    }

    _map = map;

    _map.setCenter(
      new window.naver.maps.LatLng(position.latitude, position.longitude)
    );

    currentMarker = new window.naver.maps.Marker({
      map: _map,
      position: new window.naver.maps.LatLng(
        position.latitude,
        position.longitude
      ),
      title: "Current",
      icon: {
        url: `/assets/images/marker-current.png`,
        size: new window.naver.maps.Size(20, 20),
        scaledSize: new window.naver.maps.Size(20, 20),
      },
      zIndex: 100,
    });

    setUpdated(true);
  };

  return (
    <MapStore.Provider value={{ state, actions }}>{children}</MapStore.Provider>
  );
};

export { MapStore, MapProvider };
