import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import { ConveniencesProvider } from "./stores/conveniences";
import { MapProvider } from "./stores/map";

import Postcode from "./pages/Postcode";
import Map from "./pages/Map";
import MapGshalf from "./pages/MapGshalf";

const GlobalStyle = createGlobalStyle`
  *, :after, :before {
    box-sizing: border-box;
  }
`;

const AppProvider = ({ contexts, children }) =>
  contexts.reduce(
    (prev, context) =>
      React.createElement(context, {
        children: prev,
      }),
    children
  );

const Root = () => (
  <AppProvider contexts={[ConveniencesProvider, MapProvider]}>
    <GlobalStyle />
    <BrowserRouter>
      <div>
        <Switch>
          <Route exact path="/postcode" component={Postcode} />
          <Route exact path="/map" component={Map} />
          <Route exact path="/map-gshalf" component={MapGshalf} />
        </Switch>
      </div>
    </BrowserRouter>
  </AppProvider>
);

export default Root;
