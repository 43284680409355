import React, { useEffect } from "react";
import styled from "styled-components";

const MapContainer = styled.div``;

const Iframe = styled.iframe`
  width: 100%;
  height: 100vh;
  border: none;
`
export default function MapGshalf() {
  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (window.ReactNativeWebView && e.data && e.data.storeCode) {
        window.ReactNativeWebView.postMessage(JSON.stringify(e.data));
      }
    });

    return () => {
      window.removeEventListener("message");
    };
  }, []);

  return (
    <MapContainer>
      <Iframe id='frame' src={`${process.env.REACT_APP_MAP_GSHALF}`} title="GS Postbox" />
    </MapContainer>
  );
}
